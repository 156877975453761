export const API_URL_AUX = "http://localhost:3000";
export const API_URL = "https://api.docthor.com.br/api";
export const API_BANKSLIP_URL = "https://fono.online/bankslip"; //"http://localhost:21151/bankslip"; //"https://fono.online/bankslip";
export const API_REPORTS_URL = "https://fono.online/reports";
export const API_INVOICE_URL = "https://api.plugnotas.com.br";
export const X_API_KEY = "84102ec06f62f02198401d81d40fed01";

/*
{
  proprietario: Identificador do dono da conta 
  beneficiario: Está na planilha enviada pelo banco UNICRED > Id Beneficiario
  clientId: Essa informação está https://developer.unicred.com.br > Dev Tools > Minas Apps > selecione a opcção DETALHE > CÓDIGO
  urlCoperativa: Está na planilha enviada pelo banco UNICRED > Usuário
  codCoperativa: "Está na planilha enviada pelo banco UNICRED > Senha

  HOMOLOGACAO
   {
        proprietario: "AUDIOTEX",
        beneficiario: "162B62923A8B46CFB5E567A23E2D621A",
        clientId: "2b18e3a2-1cfa-4612-bed3-17a5d69c5c3b",
        urlCoperativa: "svc.bnf.homolog", //usuario
        codCoperativa: "uL&CtJ5J", //senha
      },
},

*/

export const BANKSLIP = [
  {
    proprietario: "AUDIOTEX",
    beneficiario: "84832B12DB8142F0B97853B6704D9238",
    clientId: "2b18e3a2-1cfa-4612-bed3-17a5d69c5c3b",
    urlCoperativa: "svc.bnf.audiotexaaud", //usuario
    codCoperativa: '6mMJ"3ksVOs4q', //senha
  },
  {
    proprietario: "HB MATRIZ",
    beneficiario: "E575E332368D4C93A63D8499BA7DCF3C",
    clientId: "adf2c01e-e573-4bc5-9b78-449488782c27",
    urlCoperativa: "svc.bnf.hbaparaudit", //usuario
    codCoperativa: "ac94deBkC=D*!", //senha
  },
  {
    proprietario: "Lanna Bastos",
    beneficiario: "D80FB00A984841A28AA79030586DC003",
    clientId: "80e958ce-00c1-4ab8-9c14-b4e268c94afe",
    urlCoperativa: "svc.bnf.lannabascaud", //usuario
    codCoperativa: "AmlZ7p!wa0.^i", //senha
  },
  {
    proprietario: "MACEDO BASTOS",
    beneficiario: "2833369978454B439808D69DB6572ABD",
    clientId: "b4409653-4f70-40a6-8fd0-4ee841b065a8",
    urlCoperativa: "svc.bnf.macedbascaud", //usuario
    codCoperativa: "2eEN=ATg7b:pk", //senha
  },
  {
    proprietario: "OLIVEIRA BASTOS",
    beneficiario: "CA92FA7C5D5045A1895580EB221F44D0",
    clientId: "2ec09e0b-a454-4540-98da-e6c11e1be423",
    urlCoperativa: "svc.bnf.olivebascaud", //usuario
    codCoperativa: "3p0iV?vih7Upi", //senha
  },
  {
    proprietario: "CUNHA BASTOS",
    beneficiario: "4FE6588EF9C440D7819AA7B48243E3A0",
    clientId: "1e942c17-796f-4a30-8a12-2b40338c5119",
    urlCoperativa: "svc.bnf.cunhabascaud", //usuario
    codCoperativa: "1vJ::6GfWt9ys", //senha
  },
  {
    proprietario: "OUVIR MATRIZ",
    beneficiario: "9E2E7C8B6FE5466AB64909294C1BC315",
    clientId: "00ad9a52-0ff1-4d2b-a405-93c9c2d68ac3",
    urlCoperativa: "svc.bnf.ouviraparaud", //usuario
    codCoperativa: "0mUMi2v6o3g!m", //senha
  },
  {
    proprietario: "HR",
    beneficiario: "49DFA8D9EAD448B89F38ABA006F9604C",
    clientId: "46913470-b6a2-4b62-8f24-5c1ecf2cd554",
    urlCoperativa: "svc.bnf.corbastcaaud", //usuario
    codCoperativa: "65Ldw)UJz5Yp", //senha
  },
  {
    proprietario: "CRISTAL ANJOS",
    beneficiario: "E31A5EF383964596A335E37C4594F894",
    clientId: "e9e90d83-45b7-41d8-9654-eb376d8058b0",
    urlCoperativa: "svc.bnf.crisanjoaaud", //usuario
    codCoperativa: "6d18s=3JngoHu", //senha
  },
  {
    proprietario: "BRANDAO BASTOS",
    beneficiario: "97A6BABDA53B4C0DA7C27F1AA8ED78F3",
    clientId: "9808c811-44ae-40f8-bc52-ef05037a09e3",
    urlCoperativa: "svc.bnf.bradbastaudi", //usuario
    codCoperativa: "0ksy=iswuK599", //senha
  },
  {
    proprietario: "AUDIOVIVER",
    beneficiario: "3B33D7B940A6453E8496C5AC0402EC65",
    clientId: "44b15982-53ac-4a02-a3a5-01d8fe0889b6",
    urlCoperativa: "svc.bnf.olivvisaoaud", //usuario
    codCoperativa: "d3jGKVQdo7d#0M", //senha
  },
  {
    proprietario: "HAS",
    beneficiario: "91BEC94461954BE0A696040C3E790CC1",
    clientId: "3a5f0504-f18c-436c-accc-90c166eda17a",
    urlCoperativa: "svc.bnf.hasaparaudit", //usuario
    codCoperativa: "LMZbV5U4@lutdt", //senha
  },
  {
    proprietario: "ESCUTAR",
    beneficiario: "91BEC94461954BE0A696040C3E790CC1",
    clientId: "3a5f0504-f18c-436c-accc-90c166eda17a",
    urlCoperativa: "svc.bnf.hasaparaudit", //usuario
    codCoperativa: "LMZbV5U4@lutdt", //senha
  },
];
