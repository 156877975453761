import {
  OrderSuggestion,
  ProductOrder,
  ProductOrderState,
} from "@/models/interfaces/material/productorder.interface";

const productOrder: ProductOrder = {
  Id: "",
  Observacao: "",
  UnidadeDestinoId: 0,
  ProfissionalId: 0,
  UnidadeId: 0,
  ProfissionalAcatadoId: 0,
  Itens: [],
  ItensAcatado: [],
  DataHoraFormatada: "",
  Codigo: 0,
  StatusPedido: 0,
};

const productOrders: Array<ProductOrder> = [];

const orderSuggestions: Array<OrderSuggestion> = [];

export const state: ProductOrderState = {
  productOrder,
  productOrders,
  orderSuggestions,
};
