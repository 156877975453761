import { API_URL } from "@/constants/env";
import { ProductOrder } from "@/models/interfaces/material/productorder.interface";
import store from "@/store";
import axios from "axios";

export const getOrderSuggestion = async (): Promise<ProductOrder> => {
  const credential = { ...store.state.login.credential };
  const tenant = credential.tenantId;
  const unit = credential.storeId;
  const entity = (
    await axios.get(
      `${API_URL}/pedido/getsugestao?tenant=${tenant}&unit=${unit}`
    )
  ).data;

  return entity;
};
