import {
  ProductOrderState,
  ProductOrder,
  OrderSuggestion,
} from "@/models/interfaces/material/productorder.interface";
import { MutationTree } from "vuex";

export const mutations: MutationTree<ProductOrderState> = {
  setProductOrders(state, payload: ProductOrder[]) {
    state.productOrders = payload;
  },

  setProductOrder(state, payload: ProductOrder) {
    state.productOrder = payload;
  },

  setOrderSuggestion(state, payload: OrderSuggestion[]) {
    state.orderSuggestions = payload;
  },

  insertProductOrder(state, payload: ProductOrder) {
    state.productOrder = payload;
  },

  removeProductOrder(state, payload: ProductOrder) {
    state.productOrder = payload;
  },

  updateProductOrder(state, payload: ProductOrder) {
    state.productOrder = payload;
  },
};
